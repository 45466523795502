<template>
	<section id="disbursement-information">
		<bg-grid>
			<bg-grid-item :col="12">
				<bg-card>
					<div class="section-title-with-button mb-16">
						<bg-text size="heading-5">
							Informasi Transfer Pendapatan
						</bg-text>
						<router-link v-if="accessEditInformasiTransferPendapatan" :to="editRoute">
							<bg-button>Ubah</bg-button>
						</router-link>
					</div>

					<bg-divider />

					<bg-grid v-if="loading" class="mt-24">
						<bg-grid-item v-for="i in 4" :cols="3" :key="i">
							<bg-skeleton width="60%" height="20px" />
						</bg-grid-item>
					</bg-grid>

					<template v-else>
						<bg-grid class="mt-24">
							<bg-grid-item :col="3">
								<bg-list-item title="Nomor Rekening">
									{{ disbursement.bank_account_number }}
								</bg-list-item>
							</bg-grid-item>

							<bg-grid-item :col="3">
								<bg-list-item title="Nama Bank">
									{{ disbursement.bank_name }}
								</bg-list-item>
							</bg-grid-item>

							<bg-grid-item :col="3">
								<bg-list-item title="Cabang Bank">
									{{ disbursement.bank_account_city }}
								</bg-list-item>
							</bg-grid-item>

							<bg-grid-item :col="3">
								<bg-list-item title="Nama Pemilik Rekening">
									{{ disbursement.bank_account_owner }}
								</bg-list-item>
							</bg-grid-item>
						</bg-grid>

						<bg-grid>
							<bg-grid-item :col="3" class="mb-0">
								<bg-list-item title="Tanggal Transfer ke Pemilik">
									{{ disbursement.transfer_date }}
								</bg-list-item>
							</bg-grid-item>
						</bg-grid>
					</template>
				</bg-card>
			</bg-grid-item>
		</bg-grid>
	</section>
</template>

<script>
import {
	BgGrid,
	BgGridItem,
	BgCard,
	BgText,
	BgDivider,
	BgListItem,
	BgSkeleton,
	BgButton
} from 'bangul-vue';

export default {
	name: 'DisbursementInformation',

	components: {
		BgText,
		BgGrid,
		BgGridItem,
		BgCard,
		BgDivider,
		BgListItem,
		BgSkeleton,
		BgButton
	},

	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		disbursement: {
			type: Object,
			default: () => ({}),
		},
	},

	computed: {
		accessEditInformasiTransferPendapatan() {
			return this.$store.getters.xCheckUserPermission('edit-informasi-transfer-pendapatan');
		},
		propertyId() {
			return this.$route.params.propertyId;
		},
		editRoute() {
			return {
				name: 'edit.disbursement-information',
				params: {
					propertyId: this.propertyId,
				},
			};
		},
	},

	methods: {
		handleEdit() {
			this.$emit('emit-edit');
		}
	}
};
</script>
